import React from "react";
import HeroSection from "../components/sections/HeroSection";
import SectionTwo from "../components/sections/SectionTwo";
import SectionThree from "../components/sections/SectionThree";
import Partners from "../components/sections/Partners";

const HomePage = () => {
  return (
    <main>
      <div className="bg-gradient-1 text-white">
        <HeroSection />
        <Partners />
        <SectionTwo />
        <SectionThree />
      </div>
    </main>
  );
};

export default HomePage;
