import React from "react";
import Container from "../ui/Container";

const Footer = () => {
  return (
    <footer className="text-white pt-16 pb-6 bg-gradient-1">
      <Container>
        <div className="">
          <div className="">
            <div className="my-2">
              <ul className="flex gap-2 mx-auto justify-center">
                <li className="">
                  <a href="https://x.com/Forge_protocol" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="41"
                      height="40"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="#03A9F4"
                        d="M42,12.429c-1.323,0.586-2.746,0.977-4.247,1.162c1.526-0.906,2.7-2.351,3.251-4.058c-1.428,0.837-3.01,1.452-4.693,1.776C34.967,9.884,33.05,9,30.926,9c-4.08,0-7.387,3.278-7.387,7.32c0,0.572,0.067,1.129,0.193,1.67c-6.138-0.308-11.582-3.226-15.224-7.654c-0.64,1.082-1,2.349-1,3.686c0,2.541,1.301,4.778,3.285,6.096c-1.211-0.037-2.351-0.374-3.349-0.914c0,0.022,0,0.055,0,0.086c0,3.551,2.547,6.508,5.923,7.181c-0.617,0.169-1.269,0.263-1.941,0.263c-0.477,0-0.942-0.054-1.392-0.135c0.94,2.902,3.667,5.023,6.898,5.086c-2.528,1.96-5.712,3.134-9.174,3.134c-0.598,0-1.183-0.034-1.761-0.104C9.268,36.786,13.152,38,17.321,38c13.585,0,21.017-11.156,21.017-20.834c0-0.317-0.01-0.633-0.025-0.945C39.763,15.197,41.013,13.905,42,12.429"
                      ></path>
                    </svg>
                  </a>
                </li>
                {/* <li className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="41"
                    height="40"
                    viewBox="0 0 48 48"
                  >
                    <radialGradient
                      id="La9SoowKGoEUHOnYdJMSEa_2mIgusGquJFz_gr1"
                      cx="24"
                      cy="10.009"
                      r="32.252"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stop-color="#8c9eff"></stop>
                      <stop offset=".368" stop-color="#889af8"></stop>
                      <stop offset=".889" stop-color="#7e8fe6"></stop>
                      <stop offset="1" stop-color="#7b8ce1"></stop>
                    </radialGradient>
                    <path
                      fill="url(#La9SoowKGoEUHOnYdJMSEa_2mIgusGquJFz_gr1)"
                      d="M40.107,12.15c-0.065-0.102-0.139-0.182-0.236-0.255c-0.769-0.578-4.671-3.339-9.665-3.875	c-0.01-0.001-0.048-0.003-0.057-0.003c-0.098,0-0.183,0.057-0.224,0.14l-0.269,0.538c0,0-0.001,0-0.001,0	c-0.017,0.033-0.026,0.071-0.026,0.111c0,0.109,0.07,0.202,0.168,0.236c0.006,0.002,0.048,0.011,0.063,0.014	c4.267,1.028,6.863,2.89,9.149,4.945c-4.047-2.066-8.044-4.001-15.009-4.001s-10.961,1.936-15.009,4.001	c2.286-2.055,4.882-3.917,9.149-4.945c0.015-0.004,0.057-0.012,0.063-0.014c0.098-0.034,0.168-0.127,0.168-0.236	c0-0.04-0.009-0.078-0.026-0.111c0,0-0.001,0-0.001,0l-0.269-0.538c-0.041-0.083-0.125-0.14-0.224-0.14	c-0.009,0-0.048,0.002-0.057,0.003c-4.994,0.536-8.896,3.297-9.665,3.875c-0.097,0.073-0.17,0.153-0.236,0.255	c-0.708,1.107-5.049,8.388-5.892,21.632c-0.009,0.142,0.04,0.289,0.135,0.395c4.592,5.144,11.182,5.752,12.588,5.823	c0.167,0.008,0.327-0.065,0.427-0.199l1.282-1.709c0.1-0.134,0.046-0.322-0.111-0.379c-2.705-0.986-5.717-2.7-8.332-5.706	C11.231,34.457,16.12,37,24,37s12.769-2.543,16.009-4.993c-2.616,3.006-5.627,4.719-8.332,5.706	c-0.157,0.057-0.211,0.245-0.111,0.379l1.282,1.709c0.101,0.134,0.26,0.208,0.427,0.199c1.407-0.072,7.996-0.679,12.588-5.823	c0.095-0.106,0.144-0.253,0.135-0.395C45.156,20.538,40.815,13.257,40.107,12.15z"
                    ></path>
                    <ellipse
                      cx="30.5"
                      cy="26"
                      opacity=".05"
                      rx="4.5"
                      ry="5"
                    ></ellipse>
                    <ellipse
                      cx="30.5"
                      cy="26"
                      opacity=".05"
                      rx="4"
                      ry="4.5"
                    ></ellipse>
                    <ellipse
                      cx="30.5"
                      cy="26"
                      fill="#fff"
                      rx="3.5"
                      ry="4"
                    ></ellipse>
                    <ellipse
                      cx="17.5"
                      cy="26"
                      opacity=".05"
                      rx="4.5"
                      ry="5"
                    ></ellipse>
                    <ellipse
                      cx="17.5"
                      cy="26"
                      opacity=".05"
                      rx="4"
                      ry="4.5"
                    ></ellipse>
                    <ellipse
                      cx="17.5"
                      cy="26"
                      fill="#fff"
                      rx="3.5"
                      ry="4"
                    ></ellipse>
                  </svg>
                </li>
                <li className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="41"
                    height="40"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#3dd9eb"
                      d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                    ></path>
                    <path
                      fill="#c2ffff"
                      d="M11.553,23.717c6.99-3.075,16.035-6.824,17.284-7.343c3.275-1.358,4.28-1.098,3.779,1.91 c-0.36,2.162-1.398,9.319-2.226,13.774c-0.491,2.642-1.593,2.955-3.325,1.812c-0.833-0.55-5.038-3.331-5.951-3.984 c-0.833-0.595-1.982-1.311-0.541-2.721c0.513-0.502,3.874-3.712,6.493-6.21c0.343-0.328-0.088-0.867-0.484-0.604 c-3.53,2.341-8.424,5.59-9.047,6.013c-0.941,0.639-1.845,0.932-3.467,0.466c-1.226-0.352-2.423-0.772-2.889-0.932 C9.384,25.282,9.81,24.484,11.553,23.717z"
                    ></path>
                  </svg>
                </li>
                <li className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="41"
                    height="40"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#FF3D00"
                      d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"
                    ></path>
                    <path fill="#FFF" d="M20 31L20 17 32 24z"></path>
                  </svg>
                </li> */}
              </ul>
            </div>
          </div>
          {/* <div className="">
            <ul className="flex gap-9 justify-center md:list-disc flex-col md:flex-row">
              <li className="hover:text-gray-500">Contact Us</li>
              <li className="hover:text-gray-500">Careers</li>
              <li className="hover:text-gray-500">Roadmap</li>
              <li className="hover:text-gray-500">Assets</li>
              <li className="hover:text-gray-500">Privacy</li>
              <li className="hover:text-gray-500">English</li>
            </ul>
          </div> */}
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
