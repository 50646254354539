import { useState } from "react";
import React from "react";
import Container from "../ui/Container";

const SectionTwo = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <section id="integrate" className="relative py-12 md:py-24 overflow-hidden">
      <Container>
        <div className="relative">
          <div className="absolute bottom-0 right-0 mr-20 w-186 h-186 bg-gradient-to-t from-violet-900 via-darkBlue-900 to-transparent rounded-full filter blur-4xl" />
          <div className="relative">
            <h2 className="font-heading text-center text-3xl md:text-5xl font-semibold text-white tracking-tight pb-6">
              Ready-to-integrate developer tools for your dApp.
            </h2>
          </div>
          <div className="text-center">
            <div className="container mx-auto py-3 mt-8 ">
              <div className="flex space-x-4 justify-center">
                <button
                  className={`px-4 py-1 rounded-xl shadow-xl shadow-slate-950 hover:bg-[#0E0D12] ${
                    activeTab === "tab1"
                      ? "bg-[#0E0D12] text-white"
                      : "bg-[#C1963D]"
                  }`}
                  onClick={() => handleTabClick("tab1")}
                >
                  Data Protector
                </button>
                <button
                  className={`px-4 py-2 rounded-xl shadow-xl shadow-slate-950 hover:bg-gray-900 ${
                    activeTab === "tab2"
                      ? "bg-[#0E0D12] text-white"
                      : "bg-[#C1963D]"
                  }`}
                  onClick={() => handleTabClick("tab2")}
                >
                  Web Mail
                </button>
                <button
                  className={`px-4 py-2 rounded-xl shadow-xl shadow-slate-950 hover:bg-gray-900 ${
                    activeTab === "tab3"
                      ? "bg-[#0E0D12] text-white"
                      : "bg-[#C1963D]"
                  }`}
                  onClick={() => handleTabClick("tab3")}
                >
                  Oracle Factory
                </button>
              </div>
              <div className="mt-4 ">
                {activeTab === "tab1" && (
                  <div className="">
                    <h1 className="text-xl pt-4">
                      Enable users to grant access to their data without
                      disclosing the actual data.
                    </h1>
                    <img
                      src="/assets/images/tab1.webp"
                      className="text-center mx-auto"
                      alt=""
                    />
                  </div>
                )}
                {activeTab === "tab2" && (
                  <div className="">
                    <h1 className="text-xl pt-4">
                      Send emails to Ethereum account holders without storing or
                      knowing their email addresses.
                    </h1>{" "}
                    <img
                      src="/assets/images/tab2.webp"
                      className="text-center mx-auto"
                      alt=""
                    />
                  </div>
                )}
                {activeTab === "tab3" && (
                  <div className="">
                    <h1 className="text-xl pt-4">
                      Oracles bridge blockchains with real-world data. Our tool
                      lets you create custom oracles from any API in minutes.
                    </h1>{" "}
                    <img
                      src="/assets/images/tab3.webp"
                      className="text-center mx-auto"
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <h1 className="text-4xl text-center  font-bold my-5 pb-7">
            {" "}
            Earn crypto by securely monetizing your digital assets
          </h1>
          <div className="flex lg:flex-row flex-col gap-4 justify-center">
            <div className=" p-4  hover:bg-black">
              <div className="">
                <img
                  src="/assets/images/th.jfif"
                  className="w-full h-80"
                  alt=""
                />
              </div>
              <div className="py-2 ">
                <h1 className="text-xl font-semibold text-[#C1963D]">
                  Worker Pass
                </h1>
                <h1 className="">
                  Earn governance tokens by connecting your computer to Forge
                  Workerpools and executing computational tasks
                </h1>
                <h1 className="">Stay Tuned for Worker Pass</h1>
              </div>
            </div>
            <div className=" p-4  hover:bg-black">
              <div className="">
                <img
                  src="/assets/images/th1.jfif"
                  className="w-full h-80"
                  alt=""
                />
              </div>
              <div className="py-2">
                <h1 className="text-xl font-semibold  text-[#C1963D]">
                  Privacy Pass
                </h1>
                <h1 className="">
                  Earn governance tokens by receiving marketing emails. Your
                  email address is never revealed to partners emailing you.
                </h1>
                <h1 className="">Stay Tuned for Privacy Pass</h1>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SectionTwo;
