import React from "react";
import Container from "../ui/Container";

const HeroSection = () => {
  return (
    <section id="hero-section" className="relative overflow-hidden">
      <Container>
        <div className="pt-16 pb-8 lg:py-8">
          <div className="lg:py-8 space-y-3  flex flex-col items-center">
            <div className="flex flex-col lg:flex-row items-center lg:space-x-8">
              <div className="lg:w-1/2 flex flex-col space-y-16">
                <div className="flex flex-col space-y-8">
                  <div className="flex flex-col space-y-5">
                    <h1 className="text-4xl md:text-5xl xl:text-6xl  md:pb-8 font-bold">
                      Develop, Own and Monetize from Web3
                    </h1>
                    <h2 className="text-xl lg:text-2xl font-regular">
                      Empowering users with unparalleled control over the
                      ownership, privacy, and monetization of their data and
                      digital assets within a decentralized framework.
                    </h2>
                  </div>
                  {/* <div className="md:flex md:flex-row md:space-x-4 space-y-4 md:space-y-0 items-center">
                    <button
                      type="button"
                      className="text-center relative justify-center rounded-md border-none  py-3 px-6 text-lg font-semibold block magic-button cursor-pointer disabled:cursor-default"
                    >
                      <span className="align-middle flex justify-center items-center ">
                        Start Building Now
                      </span>
                    </button>
                  </div> */}
                </div>
              </div>
              <div className="w-1/2 hidden lg:flex justify-center">
                {/* <video
                  autoPlay
                  playsInline
                  loop
                  muted
                  title="Crypto trading dashboard showing the top performing bots and bot performance statistics"
                  className="relative h-[550px] z-10 m-auto rounded-2xl"
                  preload="none"
                  poster="/assets/images/poster-video.png"
                >
                  <source
                    src="/assets/videos/hero-video.mp4"
                    type="video/mp4"
                  />
                </video> */}
                <img
                  src="/assets/images/robot-hand-header.png"
                  alt=""
                  srcset=""
                  className="up-down left-right"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HeroSection;
