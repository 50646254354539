import React from "react";
import Container from "../ui/Container";

const Partners = () => {
  const logos = [
    { img: "/assets/images/DO_Logo_Vertical_White.png" },
    { img: "/assets/images/HostingerLogoWhite-1024x205.webp" },
    { img: "/assets/images/395-3959138_image-linode-llc-hd-png-download.png" },
    { img: "/assets/images/mochahost-logo-on-dark.png" },
    { img: "/assets/images/LOGO_IONOS_White_RGB.png" },
    { img: "/assets/images/wave-white--transparent-md.svg" },
    { img: "/assets/images/DO_Logo_Vertical_White.png" },
    { img: "/assets/images/HostingerLogoWhite-1024x205.webp" },
    { img: "/assets/images/395-3959138_image-linode-llc-hd-png-download.png" },
    { img: "/assets/images/mochahost-logo-on-dark.png" },
    { img: "/assets/images/LOGO_IONOS_White_RGB.png" },
    { img: "/assets/images/wave-white--transparent-md.svg" },
    { img: "/assets/images/DO_Logo_Vertical_White.png" },
    { img: "/assets/images/HostingerLogoWhite-1024x205.webp" },
    { img: "/assets/images/395-3959138_image-linode-llc-hd-png-download.png" },
    { img: "/assets/images/mochahost-logo-on-dark.png" },
    { img: "/assets/images/LOGO_IONOS_White_RGB.png" },
    { img: "/assets/images/wave-white--transparent-md.svg" },
    { img: "/assets/images/DO_Logo_Vertical_White.png" },
    { img: "/assets/images/HostingerLogoWhite-1024x205.webp" },
    { img: "/assets/images/395-3959138_image-linode-llc-hd-png-download.png" },
    { img: "/assets/images/mochahost-logo-on-dark.png" },
    { img: "/assets/images/LOGO_IONOS_White_RGB.png" },
    { img: "/assets/images/wave-white--transparent-md.svg" },
    { img: "/assets/images/DO_Logo_Vertical_White.png" },
    { img: "/assets/images/HostingerLogoWhite-1024x205.webp" },
    { img: "/assets/images/395-3959138_image-linode-llc-hd-png-download.png" },
    { img: "/assets/images/mochahost-logo-on-dark.png" },
    { img: "/assets/images/LOGO_IONOS_White_RGB.png" },
    { img: "/assets/images/wave-white--transparent-md.svg" },
    { img: "/assets/images/DO_Logo_Vertical_White.png" },
    { img: "/assets/images/HostingerLogoWhite-1024x205.webp" },
    { img: "/assets/images/395-3959138_image-linode-llc-hd-png-download.png" },
    { img: "/assets/images/mochahost-logo-on-dark.png" },
    { img: "/assets/images/LOGO_IONOS_White_RGB.png" },
    { img: "/assets/images/wave-white--transparent-md.svg" },
    { img: "/assets/images/DO_Logo_Vertical_White.png" },
    { img: "/assets/images/HostingerLogoWhite-1024x205.webp" },
    { img: "/assets/images/395-3959138_image-linode-llc-hd-png-download.png" },
    { img: "/assets/images/mochahost-logo-on-dark.png" },
    { img: "/assets/images/LOGO_IONOS_White_RGB.png" },
    { img: "/assets/images/wave-white--transparent-md.svg" },
    { img: "/assets/images/395-3959138_image-linode-llc-hd-png-download.png" },
    { img: "/assets/images/mochahost-logo-on-dark.png" },
    { img: "/assets/images/LOGO_IONOS_White_RGB.png" },
    { img: "/assets/images/wave-white--transparent-md.svg" },
    { img: "/assets/images/DO_Logo_Vertical_White.png" },
    { img: "/assets/images/HostingerLogoWhite-1024x205.webp" },
    { img: "/assets/images/395-3959138_image-linode-llc-hd-png-download.png" },
    { img: "/assets/images/mochahost-logo-on-dark.png" },
    { img: "/assets/images/LOGO_IONOS_White_RGB.png" },
    { img: "/assets/images/wave-white--transparent-md.svg" },
    { img: "/assets/images/DO_Logo_Vertical_White.png" },
    { img: "/assets/images/HostingerLogoWhite-1024x205.webp" },
    { img: "/assets/images/395-3959138_image-linode-llc-hd-png-download.png" },
    { img: "/assets/images/mochahost-logo-on-dark.png" },
    { img: "/assets/images/LOGO_IONOS_White_RGB.png" },
    { img: "/assets/images/wave-white--transparent-md.svg" },
    { img: "/assets/images/DO_Logo_Vertical_White.png" },
    { img: "/assets/images/HostingerLogoWhite-1024x205.webp" },
    { img: "/assets/images/395-3959138_image-linode-llc-hd-png-download.png" },
    { img: "/assets/images/mochahost-logo-on-dark.png" },
    { img: "/assets/images/LOGO_IONOS_White_RGB.png" },
    { img: "/assets/images/wave-white--transparent-md.svg" },
  ];
  return (
    <div className="">
      <Container>
        <div className="overflow-hidden mt-8">
          <h1 className="text-center text-2xl font-medium pb-7">
            Strategic Partners
          </h1>
          <div className=" marquee-left">
            <div className="flex gap-16 ">
              {logos.map((item) => (
                <img
                  src={item.img}
                  alt=""
                  className="w-14 h-12 object-contain   "
                />
              ))}
            </div>
          </div>
          {/* <div className=" pt-5 marquee-right">
            <div className="flex gap-16">
              {logos.map((item) => (
                <img
                  src={item.img}
                  alt=""
                  className="w-14 h-12 object-contain   "
                />
              ))}
            </div>
          </div> */}
        </div>
      </Container>
    </div>
  );
};

export default Partners;
